<template>
  <div class="start-page">
    <div class="start-page__header">
      <div class="start-page__logo">
        <router-link :to="{ name: 'AuthStatus' }">
          <img src="@/assets/logo.svg" alt="logo" />
        </router-link>
      </div>
      <div class="start-page__title">
        <span>Личный кабинет студента</span>
      </div>
    </div>
    <div class="start-page__card">
      <div class="start-page__flipper">
        <router-view @email-changed="changeEmail" :savedEmail="email" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StartPage",
  data() {
    return {
      isFlipped: false,
      authStatus: "reg",
      email: "",
    };
  },
  methods: {
    changeEmail(value) {
      this.email = value;
    },
  },
};
</script>

<style lang="scss"></style>
